define("discourse/plugins/discourse-lazy-videos/discourse/components/lazy-video", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/template", "discourse/helpers/concat-class", "discourse/plugins/discourse-lazy-videos/discourse/components/lazy-iframe", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _modifier, _object, _template, _concatClass, _lazyIframe, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _LazyVideo;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let LazyVideo = _exports.default = (_class = (_LazyVideo = class LazyVideo extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isLoaded", _descriptor, this);
    }
    get thumbnailStyle() {
      const color1 = this.args.videoAttributes.dominantColor;
      if (color1?.match(/^[0-9A-Fa-f]+$/)) {
        return (0, _template.htmlSafe)(`background-color: #${color1};`);
      }
    }
    loadEmbed() {
      if (!this.isLoaded) {
        this.isLoaded = true;
        this.args.onLoadedVideo?.();
      }
    }
    onKeyPress(event1) {
      if (event1.key === "Enter") {
        event1.preventDefault();
        this.loadEmbed();
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        data-video-id={{@videoAttributes.id}}
        data-video-title={{@videoAttributes.title}}
        data-video-start-time={{@videoAttributes.startTime}}
        data-provider-name={{@videoAttributes.providerName}}
        class={{concatClass
          "lazy-video-container"
          (concat @videoAttributes.providerName "-onebox")
          (if this.isLoaded "video-loaded")
        }}
      >
        {{#if this.isLoaded}}
          <LazyIframe
            @providerName={{@videoAttributes.providerName}}
            @title={{@videoAttributes.title}}
            @videoId={{@videoAttributes.id}}
            @startTime={{@videoAttributes.startTime}}
          />
        {{else}}
          <div
            {{on "click" this.loadEmbed}}
            {{on "keypress" this.loadEmbed}}
            tabindex="0"
            style={{this.thumbnailStyle}}
            class={{concatClass "video-thumbnail" @videoAttributes.providerName}}
          >
            <img
              src={{@videoAttributes.thumbnail}}
              title={{@videoAttributes.title}}
              loading="lazy"
              class={{concat @videoAttributes.providerName "-thumbnail"}}
            />
            <div
              class={{concatClass
                "icon"
                (concat @videoAttributes.providerName "-icon")
              }}
            ></div>
          </div>
          <div class="title-container">
            <div class="title-wrapper">
              <a
                href={{@videoAttributes.url}}
                title={{@videoAttributes.title}}
                target="_blank"
                rel="noopener noreferrer"
                class="title-link"
              >
                {{@videoAttributes.title}}
              </a>
            </div>
          </div>
        {{/if}}
      </div>
    
  */
  {
    "id": "1eaF/Mtc",
    "block": "[[[1,\"\\n    \"],[10,0],[15,\"data-video-id\",[30,1,[\"id\"]]],[15,\"data-video-title\",[30,1,[\"title\"]]],[15,\"data-video-start-time\",[30,1,[\"startTime\"]]],[15,\"data-provider-name\",[30,1,[\"providerName\"]]],[15,0,[28,[32,0],[\"lazy-video-container\",[28,[32,1],[[30,1,[\"providerName\"]],\"-onebox\"],null],[52,[30,0,[\"isLoaded\"]],\"video-loaded\"]],null]],[12],[1,\"\\n\"],[41,[30,0,[\"isLoaded\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@providerName\",\"@title\",\"@videoId\",\"@startTime\"],[[30,1,[\"providerName\"]],[30,1,[\"title\"]],[30,1,[\"id\"]],[30,1,[\"startTime\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,0],[24,\"tabindex\",\"0\"],[16,5,[30,0,[\"thumbnailStyle\"]]],[16,0,[28,[32,0],[\"video-thumbnail\",[30,1,[\"providerName\"]]],null]],[4,[32,3],[\"click\",[30,0,[\"loadEmbed\"]]],null],[4,[32,3],[\"keypress\",[30,0,[\"loadEmbed\"]]],null],[12],[1,\"\\n          \"],[10,\"img\"],[15,\"src\",[30,1,[\"thumbnail\"]]],[15,\"title\",[30,1,[\"title\"]]],[14,\"loading\",\"lazy\"],[15,0,[28,[32,1],[[30,1,[\"providerName\"]],\"-thumbnail\"],null]],[12],[13],[1,\"\\n          \"],[10,0],[15,0,[28,[32,0],[\"icon\",[28,[32,1],[[30,1,[\"providerName\"]],\"-icon\"],null]],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"title-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"url\"]]],[15,\"title\",[30,1,[\"title\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"title-link\"],[12],[1,\"\\n              \"],[1,[30,1,[\"title\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@videoAttributes\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-lazy-videos/discourse/components/lazy-video.js",
    "scope": () => [_concatClass.default, _helper.concat, _lazyIframe.default, _modifier.on],
    "isStrictMode": true
  }), _LazyVideo), _LazyVideo), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadEmbed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmbed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyPress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyPress"), _class.prototype)), _class);
});